import React from 'react';
import {AppBar, Box, Divider, Toolbar, Typography} from '@mui/material';
import ProfileSummary from './ProfileSummary';
import { useNavigate } from 'react-router-dom';

const ProfilePage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ backgroundColor: '#121212', color: '#fff', height: '100vh', overflow: 'auto' }}>
            <ProfileSummary />
            <Typography variant="body2" sx={{ padding: 2 }}>Location</Typography>
            <Typography variant="body2" sx={{ padding: 2 }}>Subscription Period</Typography>
            <Typography variant="body2" sx={{ padding: 2 }}>D-Day</Typography>
            <Divider sx={{ backgroundColor: '#555' }} />
            <Typography variant="button" sx={{ padding: 2 }} onClick={() => navigate('/about')}>About</Typography>
            <Typography variant="button" sx={{ padding: 2 }} onClick={() => alert('Logged out')}>Log Out</Typography>
        </Box>
    );
};

export default ProfilePage;
