import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';

const ProfileSummary: React.FC = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
            <Avatar src="/path-to-image.jpg" />
            <Box sx={{ marginLeft: 2 }}>
                <Typography variant="body1">User Name</Typography>
                <Typography variant="body2">Address</Typography>
                <Typography variant="body2">Level</Typography>
            </Box>
        </Box>
    );
};

export default ProfileSummary;
