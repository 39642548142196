import React from 'react';
import {AppBar, Box, IconButton, Toolbar, Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";

const AboutPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Box>
            <AppBar position="static" sx={{ backgroundColor: '#1e1e1e' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" component="div">
                        About
                    </Typography>
                </Toolbar>
            </AppBar>
            <iframe
                src="https://www.notion.so/your-page-url"
                style={{ width: '100%', height: '100vh', border: 'none' }}
            />
        </Box>
    );
};

export default AboutPage;
