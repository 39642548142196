// src/components/NotificationPage.tsx

import React from 'react';
import { AppBar, Toolbar, Typography, List, ListItem, ListItemText, Divider, Box, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

// Example list of notifications
const notifications = [
    { id: 1, title: 'Notification 1', description: 'This is the description for notification 1.' },
    { id: 2, title: 'Notification 2', description: 'This is the description for notification 2.' },
    { id: 3, title: 'Notification 3', description: 'This is the description for notification 3.' },
    { id: 4, title: 'Notification 4', description: 'This is the description for notification 4.' },
    { id: 5, title: 'Notification 5', description: 'This is the description for notification 5.' },
    { id: 6, title: 'Notification 6', description: 'This is the description for notification 6.' },
    { id: 7, title: 'Notification 7', description: 'This is the description for notification 7.' },
    { id: 8, title: 'Notification 8', description: 'This is the description for notification 8.' },
    { id: 9, title: 'Notification 9', description: 'This is the description for notification 9.' },
    { id: 10, title: 'Notification 10', description: 'This is the description for notification 10.' },
];

const NotificationPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ backgroundColor: '#121212', color: '#fff', height: '100vh', overflow: 'auto' }}>
            <AppBar position="static" sx={{ backgroundColor: '#1e1e1e' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" component="div">
                        알림
                    </Typography>
                </Toolbar>
            </AppBar>
            <List>
                {notifications.map((notification) => (
                    <React.Fragment key={notification.id}>
                        <ListItem sx={{ color: '#fff' }}>
                            <ListItemText
                                primary={notification.title}
                                secondary={notification.description}
                                sx={{ color: '#bbb' }}
                            />
                        </ListItem>
                        <Divider sx={{ backgroundColor: '#555' }} />
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
};

export default NotificationPage;
